import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FRONT_SSO_URL } from "../config/apiConfig";

class Timeout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warning: false,
      timeoutTime: 10,
      idleMinsTime: 2,
    };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
  }

  UNSAFE_componentWillMount() {
    localStorage.removeItem("sf_exp");
  }

  componentDidMount() {
    console.log("Component did mount");
    if (this.props.location.pathname !== "/login") {
      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
      this.setWarningTimeOut(); // Ensure the warning timeout is set on mount
    }
  }

  doClearTimeOut = () => {
    console.log("Clearing timeouts");
    this.setState({ warning: false, timeoutTime: 10 });

    if (this.warnTimeout) {
      clearTimeout(this.warnTimeout);
    }

    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }

    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  };

  setWarningTimeOut = () => {
    const { idleMinsTime } = this.state;
    console.log("Setting warning timeout");
    this.warnTimeout = setTimeout(() => {
      console.log("Warning timeout triggered");
      this.warn();
    }, idleMinsTime * 60 * 1000);
  };

  resetTimeout = () => {
    console.log("Resetting timeout");
    this.doClearTimeOut();
    this.setWarningTimeOut();
  };

  warn = () => {
    console.log("Warning user, starting countdown");
    const { timeoutTime } = this.state;

    this.countdownInterval = setInterval(() => {
      this.setState(
        (prevState) => ({ timeoutTime: prevState.timeoutTime - 1 }),
        () => {
          console.log("Countdown: ", this.state.timeoutTime);
          if (this.state.timeoutTime <= 0) {
            clearInterval(this.countdownInterval);
          }
        }
      );
    }, 1000);

    this.setState({ warning: true }, () => {
      console.log("Setting logout timeout");
      this.logoutTimeout = setTimeout(() => {
        console.log("Logout timeout triggered");
        this.logout();
      }, timeoutTime * 1000);
    });
  };

  logout = () => {
    console.log("Logging out, redirecting to", FRONT_SSO_URL + "/logout");
    localStorage.clear();
    window.location.href = FRONT_SSO_URL + "/logout";
    localStorage.setItem("sf_exp", 1);
  };

  destroy = () => {
    console.log("Destroying component, cleaning up");
    this.doClearTimeOut();
    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  };

  componentWillUnmount() {
    this.destroy();
  }

  render() {
    const style = {
      background: "#e23535d9",
      display: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      color: "white",
      zIndex: 1000,
      padding: "5px",
      textAlign: "center",
    };
    const { warning, timeoutTime } = this.state;
    const isLoggedIn = localStorage.getItem("token");
    const sessionExpired = localStorage.getItem("sf_exp");

    if (warning && isLoggedIn) {
      return (
        <div style={style}>You will be timed out in {timeoutTime} seconds</div>
      );
    }

    if (sessionExpired) {
      return <div style={style}>You were timed out! Please log in again</div>;
    }

    return null;
  }
}

export default withRouter(connect()(Timeout));
